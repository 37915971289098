import { combineReducers } from 'redux'

import auth from './auth'
import layout from './layout'
import user from './user'
import notification from './notification'

import chat from './chat'
import task from './task'
import project from './project'
import purchase from './purchase'
import factory from './factory'
import think from './think'
import motivation from './motivation'
import site from './site'
import analytics from './analytics'

const rootReducer = combineReducers({
  auth,
  layout,
  user,
  notification,

  chat,
  task,
  project,
  purchase,
  factory,
  motivation,
  think,
  site,
  analytics,
})

export default rootReducer
