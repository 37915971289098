const initialState = {
  items: {
    data: [],
    pages: 0,
  },
  item: {},
  reasons: [],
}

const motivationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_MOTIVATIONS':
      return {...state, items: action.items}
      // return {
      //   ...state,
      //   items: {
      //     data: [...state.items.data, ...action.items.data],
      //     pages: action.items.pages,
      //   },
      // }
    case 'SELECT_MOTIVATION':
      return { ...state, item: action.item }
    case 'GET_REASONS':
      return { ...state, reasons: action.reasons }
    default:
      return state
  }
}

export default motivationReducer
