export const MYSQL_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const APPROVE_CODE = 'to_approval_status_id'

export const DELETE_CODE = 'delete_status_id'

export const COLORS = ['#137CBD', '#0F9960', '#D9822B', '#DB3737']

export const CHAT_PAGE_SIZE = 25

export const READY_STATE = [
  "Сокет создан. Связь еще не открыта",
  "Соединение открыто и готово к общению.",
  "Соединение закрывается.",
  "Соединение закрыто или не может быть открыто."
]
