import themeConfig from 'configs/themeConfig'

const menu_collapsed = window.localStorage.getItem('menu_collapsed')
const is_collapsed = menu_collapsed
  ? JSON.parse(menu_collapsed)
  : themeConfig.layout.menu.isCollapsed

const initialState = {
  open_drawer: false,
  content_width: themeConfig.layout.contentWidth,
  menu_collapsed: is_collapsed,
}

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'THEME_OPEN_DRAWER':
      return { ...state, open_drawer: action.value }
    case 'THEME_CONTENT_WIDTH':
      return { ...state, content_width: action.value }
    case 'THEME_MENU_COLLAPSED':
      window.localStorage.setItem('menu_collapsed', action.value)
      return { ...state, menu_collapsed: action.value }
    default:
      return state
  }
}

export default layoutReducer
