//Template config options
const themeConfig = {
  app: {
    name: 'Tracker',
    logo: require('assets/images/logo.png').default,
    locale: 'ru-RU',
    dateFormat: 'LLL'
  },
  layout: {
    skin: 'light', // light, dark,
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    contentWidth: 'full', // full, boxed
    menu: {
      isCollapsed: false, // Only Desktop
    },
    navBar: {
      sticky: true
    },
    footer: {
      type: 'sticky' // static, sticky, hidden
    },
    scrollTop: true,
  },
  breakpoint: {
    hd: 1600,
    md: 1200,
    sm: 992
  }
}

export default themeConfig
