const initialState = {
  factoryOrders: [],
  factoryOrder: {},
}

const FactoryOrders = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_FACTORY_ORDERS':
      return { ...state, factoryOrder: {}, factoryOrders: action.factoryOrders }
    case 'GET_FACTORY_ORDER':
      return { ...state, factoryOrder: action.factoryOrder }
    default:
      return state
  }
}
export default FactoryOrders