import { CHAT_PAGE_SIZE } from 'configs/constant'

const initialState = {
  //channels: [],
  //current_channel: [],
  //directs: [],
  //current_direct: [],
  //channel: {},
  //direct: {},
  //header: {},
  contacts: [],
  contact: {},
  thread: [],
  badges: [],
  hasMore: true,
  connection_status: null,
}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    //case 'GET_CHAT_CHANNEL':
      //return { ...state, channels: action.channels }
    // case 'SELECT_CHAT_CHANNEL':
    //   return { ...state, channel: action.channel, header: action.channel }
    // case 'GET_CHAT_CHANNEL':
    //   return { ...state, thread: action.current_channel }
    //case 'GET_CHAT_DIRECTS':
      //return { ...state, directs: action.directs }
    // case 'SELECT_CHAT_DIRECT':
    //   return { ...state, direct: action.direct, header: action.direct }
    // case 'GET_CHAT_DIRECT':
    //   return { ...state, thread: action.current_direct }
    case 'GET_CHAT_CONTACTS':
      return { ...state, contacts: action.contacts }
    case 'SELECT_CHAT_CONTACT':
       return { ...state, contact: action.contact }
    case 'GET_CHAT_THREAD':
      return { ...state, thread: action.thread, hasMore: action.thread.length >= CHAT_PAGE_SIZE }
    case 'MERGE_CHAT_THREAD':
      return { ...state, thread: [...action.thread, ...state.thread], hasMore: action.thread.length >= CHAT_PAGE_SIZE }
    case 'SET_BADGES':
      return { ...state, badges: action.badges }
    case 'SET_CONNECTION_STATUS':
      return { ...state, connection_status: action.connection_status }
    default:
      return state
  }
}

export default chatReducer
