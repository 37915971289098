const initialState = {
  purchases: [],
  suggest: [],
  purchase: {},
  comments: [],
  isSaved: false
}

const TaskPurchase = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PURCHASES':
      return { ...state, purchase: {}, purchases: action.purchases }
    case 'GET_SUGGEST_PURCHASES':
      return { ...state, suggest: action.suggest }
    case 'GET_PURCHASE':
      return { ...state, purchase: action.purchase }
    case 'GET_COMMENTS':
      return { ...state, comments: action.comments }
    default:
      return state
  }
}
export default TaskPurchase