const initialState = {
  profit: [],
  driver_order: [],
  sms: [],
  subscribe_list: [],
}

const AnalyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PROFIT':
      return { ...state, profit: action.profit }
    case 'GET_DRIVER_ORDER':
      return { ...state, driver_order: action.driver_order }
    case 'GET_SMS':
      return { ...state, sms: action.sms }
    case 'GET_SUBSCRIBE_ON_INSTOCK':
      return { ...state, subscribe_list: action.data }
    default:
      return state
  }
}
export default AnalyticsReducer
