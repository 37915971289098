import { Spinner } from "@blueprintjs/core"
import PropTypes from 'prop-types'


const Fallback = (props) => {
  const { size } = props

  return (
    <Spinner size={size || 50} />
  )
}

export default Fallback

Fallback.propTypes = {
  size: PropTypes.number
}