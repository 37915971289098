import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

// Error tracking
import * as Sentry from '@sentry/react'

// store
import { Provider } from 'react-redux'
import { store } from './redux/storeConfig/store'

// context
import { AbilityContext } from './utility/context/Can'
//import { ThemeContext } from './utility/context/ThemeColors'

// components
import Spinner from './components/spinner/Fallback'

// utils
// import { WebsocketProvider } from './utility/provider/Websocket'
import ability from './utility/acl/ability'

//import './assets/scss/core.scss'
import './assets/css/style.css'

//import reportWebVitals from './reportWebVitals'


if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://129cb2a67316464c95d7d92b880d48f1@o606027.ingest.sentry.io/5757790',
  })
}

const LazyApp = lazy(() => import('./App'))


ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <AbilityContext.Provider value={ability}>
        {/* <ThemeContext>
        <WebsocketProvider url="/ws"> */}
          <LazyApp />
        {/* </WebsocketProvider>
        </ThemeContext> */}
      </AbilityContext.Provider>
    </Suspense>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
