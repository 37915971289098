const initialState = {
  orders: [],
  order: {},
  // suggest: [],
  productions: [],
  // isSaved: false
}

const SiteReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_SITE_ORDERS':
      return { ...state, orders: action.orders }
    // case 'GET_SUGGEST_TASKS':
    //   return { ...state, suggest: action.suggest }
    case 'GET_SITE_ORDER':
      return { ...state, order: action.order }
    case 'GET_SITE_PRODUCTIONS':
      return { ...state, productions: action.productions }
    default:
      return state
  }
}

export default SiteReducer