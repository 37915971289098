const initialState = {
  thinks: [],
  suggest: [],
  think: {},
  comments: [],
  isSaved: false
}

const Think = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_THINKS':
      return { ...state, think: {}, thinks: action.thinks }
    case 'GET_SUGGEST_THINKS':
      return { ...state, suggest: action.suggest }
    case 'GET_THINK':
      return { ...state, think: action.think }
    case 'SELECT_THINK':
      return { ...state, think: action.think }
    case 'GET_COMMENTS':
      return { ...state, comments: action.comments }
    default:
      return state
  }
}
export default Think