const initialState = {
  events: [],
  widget_events: [],
  selects: [],
}

const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_EVENTS':
      return { ...state, events: action.events }
    case 'GET_WIDGET_EVENTS':
      return { ...state, widget_events: action.data }
    case 'SELECT_EVENTS':
      return { ...state, selects: action.selects }
    default:
      return state
  }
}
export default NotificationReducer