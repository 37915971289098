const initialState = {
  tasks: {},
  task: {},
  revisions: [],
  revision: {},
  suggest: [],
  log: [],
  marked: [],
  comments: [],
  isSaved: false,
}

const TaskReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_TASKS':
      return { ...state, tasks: action.tasks }
    case 'GET_SUGGEST_TASKS':
      return { ...state, suggest: action.suggest }
    case 'GET_TASK':
      return { ...state, task: action.task }
    case 'GET_LOG':
      return { ...state, log: action.log }
    case 'GET_MARKED':
      return { ...state, marked: action.marked }
    case 'GET_COMMENTS':
      return { ...state, comments: action.comments }
    case 'GET_REVISIONS':
      return { ...state, revisions: action.revisions }
    case 'GET_REVISION':
      return { ...state, revision: action.revision }
    default:
      return state
  }
}
export default TaskReducer
