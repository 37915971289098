const initialState = {
  user: {},
  users: [],
  employees: [],
  suggest: [],
  roles: [],
  jobTitles: [],
}

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USER':
      return { ...state, user: action.user }
    case 'SELECT_USER':
      return { ...state, user: action.user }
    case 'GET_ROLES':
      return { ...state, roles: action.roles }
    case 'GET_MOST_CHOOSE':
      return { ...state, users: action.users }
    case 'GET_EMPLOYEE':
      return { ...state, employees: action.employees }
    case 'GET_SUGGEST':
      return { ...state, suggest: action.suggest }
    case 'GET_JOB_TITLES':
      return { ...state, jobTitles: action.data }
    default:
      return state
  }
}

export default UserReducer